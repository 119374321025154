import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@corti/i18n';
import { getTasksList } from '@corti/mission-control-api';
import { useIntersectionObserver } from '@corti/react';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { getListRequestParams } from './utils';
const ITEMS_LIMIT = 20;
// eslint-disable-next-line import/no-unused-modules
export const usePaginatedTasks = ({ taskType, sortingValue, }) => {
    const { t } = useTranslation('tasks');
    const { currentUserID } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        setTasks([]);
        void fetchTasksList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskType, sortingValue]);
    const handleObserver = useCallback(async (entries) => {
        const [target] = entries;
        if (target.isIntersecting && !isLoading && offset !== null) {
            await fetchTasksList(offset);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offset, isLoading, taskType]);
    const { observerAnchorRef } = useIntersectionObserver(handleObserver);
    const fetchTasksList = async (newOffset = 0) => {
        const params = getListRequestParams(taskType, sortingValue);
        if (newOffset === null)
            return;
        try {
            setIsLoading(true);
            const res = await getTasksList(Object.assign(Object.assign({}, params), { offset: newOffset, limit: ITEMS_LIMIT, assignees: [currentUserID] }));
            if (newOffset) {
                setTasks((prev) => [...prev, ...res]);
            }
            else {
                setTasks(res);
            }
            if (res.length < ITEMS_LIMIT) {
                setOffset(null); // No more data to load
            }
            else {
                setOffset(newOffset + ITEMS_LIMIT);
            }
        }
        catch (e) {
            console.error('Could not fetch tasks', e);
            coreStore.notifications.showNotification({
                message: t('fetchTasksError', 'Failed to get Tasks'),
                type: 'error',
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    const removeTask = (taskID) => {
        setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskID));
    };
    return {
        tasks,
        isLoading,
        observerAnchorRef,
        removeTask,
    };
};
