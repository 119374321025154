import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import EmojiFlagsRoundedIcon from '@mui/icons-material/EmojiFlagsRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
export const FeedbackStatusLabel = ({ value }) => {
    const { t } = useTranslation('missionControlApp', { keyPrefix: 'callsDataGrid' });
    switch (value) {
        case 'FLAGGED':
            return (React.createElement(Base, { display: "flex", alignItems: "center", gap: 2 },
                React.createElement(EmojiFlagsRoundedIcon, { fontSize: "small", color: "action" }),
                React.createElement(Typography, { variant: "inherit", color: "hint" }, t('flagged', 'Flagged'))));
        case 'ACKNOWLEDGED':
            return (React.createElement(Base, { display: "flex", alignItems: "center", gap: 2 },
                React.createElement(CheckRoundedIcon, { fontSize: "small", color: "action" }),
                React.createElement(Typography, { variant: "inherit", color: "hint" }, t('acknowledged', 'Acknowledged'))));
        default:
            return React.createElement(Typography, { variant: "inherit" }, t('pending', 'Pending Feedback'));
    }
};
