var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css, getFocusCss, mix, rgba } from '@corti/style';
import { useTheme } from '@corti/theme';
import { ListItem } from 'lib/cortiUI/components/List';
export const SidebarItem = React.forwardRef((props, ref) => {
    const theme = useTheme();
    const { disabled, onClick, selected, children } = props, rest = __rest(props, ["disabled", "onClick", "selected", "children"]);
    const defaultBgColor = 'transparent';
    const selectedBgColor = rgba(theme.palette.background.divider, 0.2);
    const currentBgColor = selected ? selectedBgColor : defaultBgColor;
    const hoverBgColor = mix(0.9, currentBgColor, rgba(theme.palette.background.divider, 0.2));
    return (React.createElement(ListItem, Object.assign({}, rest, { ref: ref, disabled: disabled, onClick: disabled ? undefined : onClick, classes: {
            root: css({
                color: selected ? theme.palette.text.primary : theme.palette.text.secondary,
                cursor: disabled ? 'default' : 'pointer',
                padding: `${theme.space[3]}px ${theme.space[5]}px`,
                backgroundColor: `${currentBgColor} !important`,
                boxShadow: selected ? `inset 0 0 0 1px ${theme.palette.background.divider}` : 'none',
                '&:hover': {
                    backgroundColor: `${hoverBgColor} !important`,
                },
                '&:active': {
                    backgroundColor: `${selectedBgColor} !important`,
                },
                marginBottom: 0,
                ':not(:last-child)': {
                    marginBottom: 0,
                },
                userSelect: 'none',
                position: 'relative',
            }, getFocusCss({ theme })),
            selected: css({
                backgroundColor: `${selectedBgColor} !important`,
            }),
        } }), children));
});
