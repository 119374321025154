import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getOverallStats } from '@corti/mission-control-api';
import { transparentize } from '@corti/polished';
import { useTheme } from '@corti/theme';
import { coreStore } from 'browser/stores';
import { Base, ChipV2 } from 'lib/cortiUI';
import { Card, ScoreLabel } from '../../components';
import { Bar } from '../../components/Bar';
import { useParameters, useTemplate } from '../../context';
import { getComplianceStatusFromThresholds, getReviewComplianceStatusData, getReviewComplianceStatusPaletteColor, } from '../../utils';
import { GeneralPerformanceLoader } from './GeneralPerformanceLoader';
export const GeneralPerformance = () => {
    var _a, _b, _c, _d;
    const { t } = useTranslation('missionControlApp', { keyPrefix: 'generalPerformance' });
    const { palette } = useTheme();
    const { filterRequestParams } = useParameters();
    const { template } = useTemplate();
    const [data, setData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        void fetchOrganizationMetrics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, filterRequestParams]);
    const fetchOrganizationMetrics = async () => {
        if (!template) {
            console.warn('[Mission Control] Template is not defined, overall performance data is not fetched.');
            return;
        }
        setIsLoading(true);
        try {
            const responseData = await getOverallStats(template.id, filterRequestParams);
            setData(Object.assign(Object.assign({}, responseData), { average_percentage_score: Math.floor(responseData.average_percentage_score) }));
        }
        catch (e) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('fetchOrganizationMetricsError', 'Error fetching organization data'),
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    const complianceStatus = getComplianceStatusFromThresholds((_a = data === null || data === void 0 ? void 0 : data.average_percentage_score) !== null && _a !== void 0 ? _a : 0, template);
    const complianceStatusData = getReviewComplianceStatusData(complianceStatus);
    if (isLoading) {
        return React.createElement(GeneralPerformanceLoader, null);
    }
    return (React.createElement(Card, { title: t('title', 'Score'), description: t('description', 'Average for selected period'), gradientColor: transparentize(0.93, (_b = getReviewComplianceStatusPaletteColor(complianceStatus, palette)) !== null && _b !== void 0 ? _b : palette.background.card) },
        React.createElement(Base, { width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" },
            React.createElement(ScoreLabel, { value: (_c = data === null || data === void 0 ? void 0 : data.average_percentage_score) !== null && _c !== void 0 ? _c : 0, size: "large", color: complianceStatusData.color }),
            React.createElement(ChipV2, Object.assign({ variant: "outlined" }, complianceStatusData))),
        React.createElement(Bar, { value: (_d = data === null || data === void 0 ? void 0 : data.average_percentage_score) !== null && _d !== void 0 ? _d : 0, threshold: (template === null || template === void 0 ? void 0 : template.has_threshold) ? template.threshold : undefined, partialThreshold: (template === null || template === void 0 ? void 0 : template.has_partial_threshold) ? template.partial_threshold : undefined, complianceStatus: complianceStatus })));
};
