import React from 'react';
import { getDefaultTemplate } from '@corti/mission-control-api';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { trackerService } from 'browser/services/init';
import { Base } from 'lib/cortiUI';
import { List } from './List';
import { TasksLoader } from './List/TasksLoader';
import { NoTasksView } from './NoTasksView';
import { Sorting } from './Sorting';
import { getSortingOptions, usePaginatedTasks } from './utils';
export const TasksStateContainer = ({ tab }) => {
    var _a;
    const theme = useTheme();
    const SORTING_OPTIONS = getSortingOptions();
    const DEFAULT_SORTING_OPTION = SORTING_OPTIONS[0];
    const [sortingValue, setSortingValue] = React.useState(DEFAULT_SORTING_OPTION);
    const [template, setTemplate] = React.useState(null);
    const { tasks, isLoading: isTasksDataLoading, observerAnchorRef, removeTask, } = usePaginatedTasks({ taskType: tab, sortingValue: sortingValue.value });
    React.useEffect(() => {
        if (tasks.length) {
            void fetchTemplate();
        }
    }, [tasks.length]);
    async function fetchTemplate() {
        try {
            const res = await getDefaultTemplate();
            setTemplate(res);
        }
        catch (e) {
            console.error('[Tasks]: Could not fetch default template', e);
        }
    }
    function onSortingChange(option) {
        setSortingValue(option);
        trackerService.track("[Tasks] User's changed the sort option", {
            selectedOption: option,
        });
    }
    if (!isTasksDataLoading && !tasks.length) {
        return React.createElement(NoTasksView, null);
    }
    return (React.createElement(Base, { height: "100%", display: "flex", flexDirection: "column", gap: 6, overflow: "hidden" },
        React.createElement(Sorting, { options: SORTING_OPTIONS, value: sortingValue, onChange: onSortingChange }),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 6, className: css({ overflow: 'overlay' }, getScrollerCss({ theme })) }, isTasksDataLoading && tasks.length === 0 ? (React.createElement(TasksLoader, null)) : (React.createElement(React.Fragment, null,
            React.createElement(List, { tasks: tasks, templateID: (_a = template === null || template === void 0 ? void 0 : template.id) !== null && _a !== void 0 ? _a : '', onUnassignUser: (id) => removeTask(id) }),
            isTasksDataLoading && React.createElement(TasksLoader, null),
            React.createElement(Base, { ref: observerAnchorRef, width: "100%", zIndex: 1, className: css({
                    visibility: 'hidden',
                }) }))))));
};
