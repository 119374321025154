import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { Base, Collapse, Typography } from 'lib/cortiUI';
import { SingleProperty } from './SingleProperty';
export const ExpandableProperties = ({ title, items }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (React.createElement(Base, { display: "grid", gridTemplateRows: "auto 1fr", gap: 3 },
        React.createElement(Base, { display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center", onClick: () => setIsExpanded((current) => !current) },
            React.createElement(Typography, { variant: "body2", color: "default" }, title),
            isExpanded ? React.createElement(ExpandLessIcon, { fontSize: "small" }) : React.createElement(ExpandMoreIcon, { fontSize: "small" })),
        React.createElement(Collapse, { in: isExpanded },
            React.createElement(Base, { display: "grid", gap: 4 }, items.map((props) => (React.createElement(SingleProperty, Object.assign({}, props))))))));
};
