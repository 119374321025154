import React from 'react';
import { Container, Page } from 'lib/cortiUI';
import { Header } from './Header';
import { TasksStateContainer } from './TasksStateContainer';
export const TasksView = () => {
    const [tab, setTab] = React.useState('PENDING');
    return (React.createElement(Page, null,
        React.createElement(Container, { sx: {
                height: '100%',
                overflow: 'hidden',
                display: 'grid',
                gridTemplateRows: 'auto 1fr',
                gap: 5,
            } },
            React.createElement(Header, { tab: tab, onTabChange: (tab) => setTab(tab) }),
            React.createElement(TasksStateContainer, { tab: tab }))));
};
