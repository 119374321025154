import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { getReadableCaseID } from '@corti/lib/coreApiService';
import { Formatters } from '@corti/strings';
import { css } from '@corti/style';
import { Avatar, Base, Typography } from 'lib/cortiUI';
import { ListCell } from './ListCell';
import { ListRow } from './ListRow';
export function CallList(props) {
    const { calls, onRowClick, onScrollToBottom } = props;
    const { t } = useTranslation('transcriptSearchApp');
    const observableElRef = React.useRef(null);
    React.useEffect(() => {
        const intersectionObserver = new IntersectionObserver((e) => {
            if (e[0].intersectionRatio > 0) {
                onScrollToBottom === null || onScrollToBottom === void 0 ? void 0 : onScrollToBottom();
            }
        });
        if (observableElRef.current) {
            intersectionObserver.observe(observableElRef.current);
        }
        return () => {
            intersectionObserver === null || intersectionObserver === void 0 ? void 0 : intersectionObserver.disconnect();
        };
    }, [onScrollToBottom]);
    function handleRowClick(call) {
        onRowClick(call);
    }
    return (React.createElement(Base, { "data-cy": "transcript-search-call-list" },
        React.createElement(ListRow, { className: css({
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                alignItems: 'center',
            }) },
            React.createElement(ListCell, null,
                React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('callTaker', 'Call-Taker'))),
            React.createElement(ListCell, null,
                React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('startTime', 'Start Time'))),
            React.createElement(ListCell, null,
                React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('caseId', 'Case ID'))),
            React.createElement(ListCell, null,
                React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('duration', 'Duration'))),
            React.createElement(ListCell, null,
                React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('protocolName', 'Protocol Name'))),
            React.createElement(ListCell, null,
                React.createElement(Typography, { variant: "subtitle2", color: "default", noWrap: true }, t('graph', 'Graph')))),
        calls.map((call) => (React.createElement(CallListRow, { key: call.id, call: call, onClick: handleRowClick }))),
        React.createElement(Base, { ref: observableElRef, width: "100%", zIndex: 1, className: css({
                visibility: 'hidden',
            }) })));
}
function CallListRow(props) {
    var _a, _b;
    const { call, onClick } = props;
    return (React.createElement(ListRow, { className: css({
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
            alignItems: 'center',
        }), onClick: onClick ? () => onClick(call) : undefined, "data-cy": "transcript-search-call-list-row" },
        React.createElement(ListCell, null,
            React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 5 },
                React.createElement(Avatar, { size: "small" }, (_a = call.user) === null || _a === void 0 ? void 0 : _a.name),
                React.createElement(Typography, { color: "default", variant: "body1", noWrap: true }, (_b = call.user) === null || _b === void 0 ? void 0 : _b.name))),
        React.createElement(ListCell, null,
            React.createElement(Typography, { variant: "body1", color: "default", noWrap: true }, formatDateTime(call.startedAt))),
        React.createElement(ListCell, null,
            React.createElement(Typography, { variant: "body1", color: "default", noWrap: true }, call.case.id ? getReadableCaseID(call.case.id) : '')),
        React.createElement(ListCell, null,
            React.createElement(Typography, { variant: "body1", color: "default", noWrap: true }, Formatters.msToDuration(call.duration, { showHours: false }))),
        React.createElement(ListCell, null,
            React.createElement(Typography, { variant: "body1", color: "default", noWrap: true }, call.protocolName)),
        React.createElement(ListCell, null, call.termGroupColors.map((color, idx) => (React.createElement(Base, { key: idx, style: {
                height: 10,
                width: 10,
                borderRadius: 100,
                marginRight: 2,
                backgroundColor: color,
            } }))))));
}
