import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getAcknowledgementStatusStats, } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Base, Typography } from 'lib/cortiUI';
import { Card } from '../../components';
import { useParameters, useTemplate } from '../../context';
import { AssessmentFeedbackLoader, FeedbackChart } from './components';
export const AssessmentFeedback = () => {
    const { t } = useTranslation('missionControlApp', { keyPrefix: 'assessmentFeedback' });
    const { template } = useTemplate();
    const { filterRequestParams } = useParameters();
    const [assessmentFeedback, setAssessmentFeedback] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        void loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, filterRequestParams]);
    const loadData = async () => {
        var _a;
        if (!template) {
            console.warn('Template is not defined, review status stats cannot be fetched');
            return;
        }
        setIsLoading(true);
        try {
            const response = await getAcknowledgementStatusStats(template.id, Object.assign(Object.assign({}, filterRequestParams), { status: [...((_a = filterRequestParams.status) !== null && _a !== void 0 ? _a : []), 'SKIPPED'] }));
            setAssessmentFeedback(response);
        }
        catch (error) {
            coreStore.notifications.showNotification({
                message: t('fetchReviewsStatusStatsError', 'Failed to fetch review status statistics'),
                type: 'error',
            });
        }
        finally {
            setIsLoading(false);
        }
    };
    if (isLoading) {
        return React.createElement(AssessmentFeedbackLoader, null);
    }
    return (React.createElement(Card, { title: t('title', 'Acknowledgment Ratio'), tooltip: t('tooltip', 'Displays a percentage breakdown of all interactions, based on direct agent input. It helps to track, identify and prioritize interactions that need further attention, as well as provide insight into agent engagement.') },
        React.createElement(Base, { mt: 7 }, assessmentFeedback.length ? (React.createElement(FeedbackChart, { data: assessmentFeedback })) : (React.createElement(Typography, null, t('noData', 'No data'))))));
};
