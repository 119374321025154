import { isEqual } from 'lodash';
import React from 'react';
import { css } from '@corti/style';
import { Autocomplete, Base, MenuItem, TextField } from 'lib/cortiUI';
export const Sorting = ({ options, value, onChange }) => {
    return (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" },
        React.createElement(Autocomplete, { sx: { minWidth: 200 }, size: "small", options: options, value: value, onChange: (_, value) => onChange(value), disableClearable: true, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "Sort by" })), isOptionEqualToValue: (option, value) => isEqual(option, value), renderOption: (props, option, state) => (React.createElement(MenuItem, Object.assign({ "data-cy": "select-item" }, props, { selected: state.selected, key: option.label, className: css({
                    fontWeight: state.selected ? 600 : 'normal',
                }) }), option.label)) })));
};
