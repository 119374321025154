import React from 'react';
import { differenceInMilliseconds, formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { Formatters } from '@corti/strings';
import { repositories } from 'core/repositories';
import { AcknowledgementChip } from '../../../components';
import { FailedAiScoreIndicator, PendingAiScoreIndicator, Score, SkippedStatusIndicator, } from '../components';
const getCallDuration = (start, end) => {
    return Formatters.msToDuration(differenceInMilliseconds(new Date(end), new Date(start)));
};
export const getDataGridRowsData = (items, templateID) => items.map((item) => {
    var _a, _b, _c;
    const review = (_a = item === null || item === void 0 ? void 0 : item.reviews) === null || _a === void 0 ? void 0 : _a.find((r) => r.template_id === templateID && r.latest);
    return {
        id: item.interaction.id,
        dateTime: new Date(item.call.started_at),
        user: (_c = (_b = repositories.users.getUser(item.interaction.interaction_owner)) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '-',
        callDuration: getCallDuration(item.call.started_at, item.call.ended_at),
        interactionStatus: item.interaction.acknowledgement_status,
        score: {
            complianceStatus: (review === null || review === void 0 ? void 0 : review.compliance_status) || 'NONE',
            status: (review === null || review === void 0 ? void 0 : review.status) || 'NONE',
            score: typeof (review === null || review === void 0 ? void 0 : review.score_percentage) === 'number' ? Math.floor(review.score_percentage) : 0,
            notApplicable: review === null || review === void 0 ? void 0 : review.not_applicable,
        },
    };
});
export const getDataGridColumnsData = () => [
    {
        field: 'dateTime',
        headerName: intl.t('missionControlApp:callsDataGrid.dateTime', 'Time & Date'),
        editable: false,
        valueFormatter: ({ value }) => formatDateTime(value),
        type: 'dateTime',
        flex: 3,
    },
    {
        field: 'user',
        headerName: intl.t('missionControlApp:callsDataGrid.user', 'Agent'),
        editable: false,
        flex: 2,
    },
    {
        field: 'callDuration',
        headerName: intl.t('missionControlApp:callsDataGrid.duration', 'Duration'),
        editable: false,
        flex: 2,
    },
    {
        field: 'interactionStatus',
        headerName: intl.t('missionControlApp:callsDataGrid.feedbackStatus', 'Acknowledgment Status'),
        editable: false,
        flex: 2,
        renderCell: ({ value }) => {
            if (!value)
                return '-';
            return React.createElement(AcknowledgementChip, { status: value });
        },
    },
    {
        field: 'score',
        headerName: intl.t('missionControlApp:callsDataGrid.scoreStatus', 'Score Status'),
        editable: false,
        flex: 2,
        type: 'number',
        sortComparator: ((a, b) => a.score - b.score),
        renderCell: ({ value }) => {
            if (!value ||
                typeof value.score !== 'number' ||
                !value.complianceStatus ||
                value.notApplicable)
                return '-';
            switch (value.status) {
                case 'PENDING_TRANSCRIPT':
                    return React.createElement(PendingAiScoreIndicator, null);
                case 'FAILED':
                    return React.createElement(FailedAiScoreIndicator, null);
                case 'SKIPPED':
                    return React.createElement(SkippedStatusIndicator, null);
                case 'NONE':
                    return '-';
                default:
                    return React.createElement(Score, { value: value.score, status: value.complianceStatus });
            }
        },
    },
];
