import React from 'react';
import { subDays } from '@corti/date';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { useParametersCache } from './cacheHook';
const initialState = {
    teams: [],
    agents: [],
    dateRange: {
        startDate: new Date(),
        endDate: new Date(),
    },
    complianceStatus: [],
    filterRequestParams: {},
    acknowledgementStatus: [],
    updateParameters: () => undefined,
};
export const ParametersContext = React.createContext(initialState);
export const ParametersProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const cacheHandlers = useParametersCache();
    const DEFAULT_PERIOD = 7;
    const hasPermissionToReadAllTeamsAndAgents = rbacService.hasPermission('cases:other_users:read') &&
        rbacService.missionControlOtherAssessmentsRead();
    const [parameters, setParameters] = React.useState(Object.assign(Object.assign(Object.assign({}, initialState), { agents: hasPermissionToReadAllTeamsAndAgents
            ? initialState.agents
            : [{ id: currentUser.id, name: currentUser.name }], dateRange: {
            startDate: subDays(initialState.dateRange.endDate, DEFAULT_PERIOD),
            endDate: initialState.dateRange.endDate,
        } }), cacheHandlers.getPersistedParameters()));
    const updateParameters = (newParameters) => {
        setParameters((prevParameters) => (Object.assign(Object.assign({}, prevParameters), newParameters)));
        cacheHandlers.persistParameters(Object.assign(Object.assign({}, parameters), newParameters));
    };
    const getUserIds = (users, teams) => {
        return users.length
            ? users.map((user) => user.id)
            : teams.flatMap((team) => team.members.map((member) => member.user.id));
    };
    const filterRequestParams = {
        user_ids: getUserIds(parameters.agents, parameters.teams),
        call_started_after: parameters.dateRange.startDate.toISOString(),
        call_ended_before: parameters.dateRange.endDate.toISOString(),
        status: ['DONE', 'ACKNOWLEDGED', 'FLAGGED'],
        acknowledgement_status: parameters.acknowledgementStatus,
        latest_only: true,
        compliance_status: parameters.complianceStatus,
    };
    const value = React.useMemo(() => (Object.assign(Object.assign({}, parameters), { updateParameters, filterRequestParams })), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parameters]);
    return React.createElement(ParametersContext.Provider, { value: value }, children);
};
export const useParameters = () => {
    const context = React.useContext(ParametersContext);
    if (!context) {
        throw new Error('useParameters must be used within a ParametersProvider');
    }
    return context;
};
