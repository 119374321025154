import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, FallbackView, Separator } from 'lib/cortiUI';
import { Call } from './Call';
import { Metadata } from './Metadata';
import { RightSidePanel } from './RightSidePanel';
import { SectionWrapper } from './components';
import { useInteraction } from './context';
export const CaseViewContent = () => {
    const { t } = useTranslation('caseView');
    const { interaction, coreCall, isLoading } = useInteraction();
    if (!interaction && !isLoading) {
        return React.createElement(FallbackView, { title: t('noInteractionData', 'Could not load Interaction') });
    }
    if (!coreCall) {
        return null;
    }
    return (React.createElement(Base, { display: "grid", gridTemplateColumns: coreCall ? '1fr auto 3fr auto 2fr' : '1fr', overflow: "hidden" },
        React.createElement(SectionWrapper, null,
            React.createElement(Metadata, { caseID: coreCall.case.id })),
        React.createElement(Separator, null),
        React.createElement(React.Fragment, null,
            React.createElement(SectionWrapper, null,
                React.createElement(Call, { callID: coreCall.id })),
            React.createElement(Separator, null),
            React.createElement(RightSidePanel, null))));
};
