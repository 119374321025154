import React from 'react';
import { useHistory } from 'react-router';
import { InteractionProvider } from '../context';
import { Task } from './Task';
export const List = ({ tasks, templateID, onUnassignUser }) => {
    const history = useHistory();
    function handleTaskClick(interactionID) {
        history.push(`${history.location.pathname}/${interactionID}`);
    }
    return (React.createElement(React.Fragment, null, tasks.map((it) => (React.createElement(InteractionProvider, { id: it.reference_id },
        React.createElement(Task, { id: it.id, key: it.id, owner: it.created_by, assignees: it.assignees, createdAt: it.created_at, type: it.task_type, status: it.status, templateID: templateID, onClick: () => handleTaskClick(it.reference_id), onUnassignUser: () => onUnassignUser(it.id) }))))));
};
