import React from 'react';
import { getOverallStats } from '@corti/mission-control-api';
import { Comparison } from './Comparison';
import { ComparisonLoader } from './ComparisonLoader';
export const AvgComparison = ({ templateID, userIDs, score, label, }) => {
    const [data, setData] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        void fetchAverage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function fetchAverage() {
        setIsLoading(true);
        try {
            const response = await getOverallStats(templateID, {
                user_ids: userIDs,
                status: ['DONE', 'ACKNOWLEDGED', 'FLAGGED'],
                latest_only: true,
            });
            setData(Object.assign(Object.assign({}, response), { average_percentage_score: Math.floor(response.average_percentage_score) }));
        }
        catch (error) {
            console.error('failed to fetch overall stats:', error);
        }
        finally {
            setIsLoading(false);
        }
    }
    if (isLoading) {
        return React.createElement(ComparisonLoader, null);
    }
    if (!data) {
        return null;
    }
    return React.createElement(Comparison, { difference: score - data.average_percentage_score, label: label });
};
