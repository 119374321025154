var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { cloneDeep } from 'lodash';
import { computed } from 'mobx';
import { saveFileToDisk } from '@corti/browser-file';
import { intl } from '@corti/i18n';
import { logger } from '@corti/logger';
import { Formatters } from '@corti/strings';
import { TranscriptQueryModel } from '../entities';
import { TranscriptQueryViewState } from './TranscriptQueryViewState';
export class TranscriptQueryViewModel {
    constructor(searchState, api) {
        Object.defineProperty(this, "searchState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: searchState
        });
        Object.defineProperty(this, "api", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: api
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loadMoreCalls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "logger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: logger.getLogger('TranscriptQueryView')
        });
        Object.defineProperty(this, "loadQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (queryID) => {
                var _a;
                try {
                    this.state.setIsLoadingQuery(true);
                    const response = await this.api.getTranscriptQuery(queryID);
                    this.state.setQuery(new TranscriptQueryModel(response));
                }
                catch (e) {
                    if (e instanceof Error) {
                        logger.error(`Load query failed with: `, e.message, (_a = e.stack) !== null && _a !== void 0 ? _a : '');
                    }
                }
                finally {
                    this.state.setIsLoadingQuery(false);
                }
            }
        });
        Object.defineProperty(this, "createQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                try {
                    this.state.setIsSavingQuery(true);
                    const response = await this.api.createTranscriptQuery(input);
                    this.searchState.addQuery(response);
                    this.state.setQuery(new TranscriptQueryModel(response));
                    return response.id;
                }
                finally {
                    this.state.setIsSavingQuery(false);
                }
            }
        });
        Object.defineProperty(this, "updateQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (queryID, input) => {
                try {
                    this.state.setIsSavingQuery(true);
                    const response = await this.api.updateTranscriptQuery(queryID, input);
                    this.searchState.updateQuery(response);
                    this.state.setQuery(new TranscriptQueryModel(response));
                }
                finally {
                    this.state.setIsSavingQuery(false);
                }
            }
        });
        Object.defineProperty(this, "loadTimeSeries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                var _a;
                try {
                    this.state.setIsLoadingTimeSeries(true);
                    this.state.setTimeSeries([]);
                    const response = await this.api.getTimeSeriesDataPoints(input);
                    this.state.setTimeSeries(response.data);
                }
                catch (e) {
                    this.state.setTimeSeries([]);
                    if (e instanceof Error) {
                        logger.error(`Load Time Series failed with: `, e.message, (_a = e.stack) !== null && _a !== void 0 ? _a : '');
                    }
                }
                finally {
                    this.state.setIsLoadingTimeSeries(false);
                }
            }
        });
        Object.defineProperty(this, "loadCalls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                var _a;
                const inputSnapshot = cloneDeep(input);
                const loadCalls = async (nextPageToken) => {
                    this.loadMoreCalls = null;
                    const transcriptServiceRes = await this.api.getCalls(Object.assign({ pageToken: nextPageToken }, inputSnapshot));
                    const coreRes = transcriptServiceRes.data.length !== 0
                        ? await this.api.getCoreCallsMetadata(transcriptServiceRes.data.map((it) => it.id))
                        : [];
                    const mergedCalls = transcriptServiceRes.data.map((tsCall) => {
                        var _a, _b;
                        const coreCall = coreRes.find((coreCall) => coreCall.callServiceCallId === tsCall.id);
                        return Object.assign(Object.assign(Object.assign({}, tsCall), coreCall), { case: {
                                id: (_a = coreCall === null || coreCall === void 0 ? void 0 : coreCall.case.id) !== null && _a !== void 0 ? _a : '',
                            }, callServiceCallId: (_b = coreCall === null || coreCall === void 0 ? void 0 : coreCall.callServiceCallId) !== null && _b !== void 0 ? _b : tsCall.id });
                    });
                    if (nextPageToken) {
                        this.state.setCalls([...this.state.calls, ...mergedCalls]);
                    }
                    else {
                        this.state.setCalls(mergedCalls);
                    }
                    if (transcriptServiceRes.nextPageToken) {
                        this.loadMoreCalls = async () => await loadCalls(transcriptServiceRes.nextPageToken);
                    }
                };
                try {
                    this.state.setIsLoadingCalls(true);
                    this.state.setCalls([]);
                    await loadCalls();
                }
                catch (e) {
                    this.state.setCalls([]);
                    if (e instanceof Error) {
                        logger.error(`Load calls failed with: `, e.message, (_a = e.stack) !== null && _a !== void 0 ? _a : '');
                    }
                }
                finally {
                    this.state.setIsLoadingCalls(false);
                }
            }
        });
        Object.defineProperty(this, "exportCalls", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (input) => {
                try {
                    this.state.setIsLoadingExportCalls(true);
                    const res = await this.api.exportTranscriptQueryCallsCSV(input);
                    const ms = new Date().getTime();
                    const date = Formatters.msToFullYears(ms, { separator: '' });
                    const time = Formatters.msToDayTime(ms, { separator: '' });
                    const ext = '.csv';
                    saveFileToDisk(new Blob([res], { type: 'text/csv' }), `Corti-${intl.t('transcriptSearchApp:callSearch', 'call-search')}-${date}-${time}${ext}`);
                }
                finally {
                    this.state.setIsLoadingExportCalls(false);
                }
            }
        });
        this.state = new TranscriptQueryViewState();
        this.loadMoreCalls = null;
    }
    get unsuccessfulTermGroups() {
        const { query } = this.state;
        if (!query)
            return [];
        return query.parameters.termGroups
            .filter((termGroup) => {
            const timeSeries = this.state.timeSeries.find((it) => it.termGroupID === termGroup.id);
            if (this.state.isLoadingTimeSeries) {
                return false;
            }
            if (!timeSeries) {
                return true;
            }
            if (timeSeries.dataPoints.length > 0) {
                return false;
            }
            return true;
        })
            .map((termGroup) => termGroup.id);
    }
}
__decorate([
    computed,
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [])
], TranscriptQueryViewModel.prototype, "unsuccessfulTermGroups", null);
