export var ClientSettingsKeys;
(function (ClientSettingsKeys) {
    ClientSettingsKeys["triageCloseTabOnSessionEnd"] = "triageCloseTabOnSessionEnd";
    ClientSettingsKeys["casesTableColumns"] = "casesTableColumns";
    ClientSettingsKeys["dateFormat"] = "dateFormat";
    ClientSettingsKeys["timeFormat"] = "timeFormat";
    ClientSettingsKeys["timeZone"] = "timeZone";
    ClientSettingsKeys["customPropertyAsCaseID"] = "customPropertyAsCaseID";
    ClientSettingsKeys["defaultAppName"] = "defaultAppName";
    ClientSettingsKeys["teamPerformanceTargetScore"] = "teamPerformanceTargetScore";
    ClientSettingsKeys["allowSessionRecording"] = "allowSessionRecording";
    ClientSettingsKeys["disabledSCVTimelineEntryFilterDefinitions"] = "disabledSCVTimelineEntryFilterDefinitions";
})(ClientSettingsKeys || (ClientSettingsKeys = {}));
export const FEATURE_IDS_LIST = [
    'ai-models',
    'call-audio-meta-import',
    'case-filter-subscription',
    'case-review-request',
    'custom-analytics',
    'dashboard',
    'detect',
    'feedback-form-management',
    'inbox',
    'mission-control-assessment-dashboard',
    'mission-control-case-view',
    'mission-control-find-related-calls',
    'mission-control-interaction-api',
    'mission-control-review-api',
    'mission-control-search-api',
    'mission-control-task-api',
    'mission-control-task-list',
    'mission-control-team-role',
    'mission-control-template-builder',
    'performance-metrics',
    'protocol-graph-editor',
    'qa',
    'real-time',
    'review',
    'screen-recorder',
    'smart-checklists',
    'team-performance',
    'team',
    'the-finder',
    'timeline-entry-management',
    'transcript-queries',
    'transcripts',
];
export var TriageSettingsKeys;
(function (TriageSettingsKeys) {
    TriageSettingsKeys["activeProtocolGraphVersionID"] = "activeProtocolGraphVersionID";
    TriageSettingsKeys["automaticProgressToNextStep"] = "automaticProgressToNextStep";
})(TriageSettingsKeys || (TriageSettingsKeys = {}));
