import { subDays } from '@corti/date';
import { intl } from '@corti/i18n';
export function getListRequestParams(tab, sortingValue) {
    const sortingParams = mapSortingValueToServerParams(sortingValue, tab);
    switch (tab) {
        case 'COMPLETED':
            return Object.assign({ status: [tab], created_after: subDays(new Date(), 30).toISOString() }, sortingParams);
        case 'PENDING':
            return Object.assign({ status: [tab] }, sortingParams);
        default:
            return {};
    }
}
function mapSortingValueToServerParams(sortingValue, taskStatus) {
    let sortKey = 'created_at';
    switch (sortingValue.key) {
        case 'age':
            if (taskStatus === 'COMPLETED') {
                sortKey = 'completed_at';
            }
            break;
        // TODO: Uncomment when the API supports sorting by score & type
        // case 'score':
        //   sortKey = 'review_score_percentage';
        //   break;
        // case 'type':
        //   sortKey = 'task_type';
        //   break;
        default:
            sortKey = 'created_at';
            break;
    }
    return { sort_by: sortKey, sort_direction: sortingValue.value };
}
export function getSortingOptions() {
    return [
        {
            label: intl.t('tasks:sortingOptions.ageDesc', 'Age: Newest first'),
            value: {
                key: 'age',
                value: 'desc',
            },
        },
        {
            label: intl.t('tasks:sortingOptions.ageAsc', 'Age: Oldest first'),
            value: {
                key: 'age',
                value: 'asc',
            },
        },
        // TODO: Uncomment when the API supports sorting by score & type
        // {
        //   label: intl.t('tasks:sortingOptions.scoreDesc', 'Score: Highest first'),
        //   value: {
        //     key: 'score',
        //     value: 'desc',
        //   },
        // },
        // {
        //   label: intl.t('tasks:sortingOptions.scoreAsc', 'Score: Lowest first'),
        //   value: {
        //     key: 'score',
        //     value: 'asc',
        //   },
        // },
        // {
        //   label: intl.t('tasks:sortingOptions.typeAsc', 'Task Type'),
        //   value: {
        //     key: 'type',
        //     value: 'asc',
        //   },
        // },
    ];
}
