import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getTimeBasedStats } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { useParameters, useTemplate } from '../../../context';
export const useChartData = () => {
    const { t } = useTranslation('missionControlApp', { keyPrefix: 'chart' });
    const { filterRequestParams } = useParameters();
    const { template } = useTemplate();
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        void fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, filterRequestParams]);
    async function fetchData() {
        if (!template) {
            console.warn('[Mission Control] Template is not defined, chart data is not fetched.');
            return;
        }
        setIsLoading(true);
        try {
            const statistics = await getTimeBasedStats(template.id, filterRequestParams);
            setData(statistics.map((s) => ({
                timePoint: s.date,
                detectionPercentage: Math.floor(s.average_percentage_score),
                numberOfReviews: s.number_of_reviews,
            })));
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('fetchTimeBasedMetricsError', 'Error fetching time-based metrics'),
            });
        }
        finally {
            setIsLoading(false);
        }
    }
    return { data, isLoading };
};
