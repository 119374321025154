var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI';
export function ListRow(props) {
    const { children, className, onClick } = props, rest = __rest(props, ["children", "className", "onClick"]);
    const theme = useTheme();
    const SPACING = theme.space[5];
    function handleClick() {
        onClick && onClick();
    }
    function getClickableStyles() {
        if (onClick) {
            return css({
                cursor: 'pointer',
                position: 'relative',
                zIndex: 0,
                '&:hover': {
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: `calc(100% + ${SPACING * 2}px)`,
                        backgroundColor: theme.palette.background.card,
                        zIndex: -1,
                        boxShadow: `0px 0px 0px 1px ${theme.palette.background.divider}`,
                        borderRadius: Number(theme.shape.borderRadius) * 2,
                        marginLeft: -SPACING,
                        marginRight: -SPACING,
                    },
                },
            });
        }
    }
    return (React.createElement(Base, Object.assign({ onClick: handleClick, className: css({
            width: `calc(100% - ${SPACING * 2}px)`,
            minHeight: 85,
            marginLeft: SPACING,
            marginRight: SPACING,
            '&:not(:last-child)': { borderBottom: `1px solid ${theme.palette.background.divider}` },
        }, getClickableStyles(), className) }, rest), children));
}
