var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { api } from '../MissionControlService';
import { formatArrayQueryParams } from '../utils';
export async function searchInteractions(_a) {
    var { user_ids = [], status = [], review_status = [], call_type = [], review_compliance_status = [] } = _a, restParams = __rest(_a, ["user_ids", "status", "review_status", "call_type", "review_compliance_status"]);
    const formattedParams = formatArrayQueryParams(['user_ids', user_ids], ['status', status], ['review_status', review_status], ['review_compliance_status', review_compliance_status], ['call_type', call_type]);
    return await api
        .get('/search/interactions', {
        params: Object.assign(Object.assign({}, formattedParams), restParams),
    })
        .then((response) => response.data);
}
export * from './types';
