import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getReviewsByCall } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { Loading } from 'lib/cortiUI';
import { useInteraction } from '../../context';
import { FailedAiScoreIndicator } from './FailedAiScoreIndicator';
import { PendingAiScoreIndicator } from './PendingAiScoreIndicator';
import { ScoreChip } from './ScoreChip';
import { SkippedStatusIndicator } from './SkippedStatusIndicator';
export const Score = ({ templateID }) => {
    const { t } = useTranslation('tasks', { keyPrefix: 'score' });
    const { interaction } = useInteraction();
    const [groundTruthReview, setGroundTruthReview] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        if (interaction && templateID) {
            void fetchGroundTruthReview();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interaction, templateID]);
    async function fetchGroundTruthReview() {
        if (!interaction)
            return;
        setIsLoading(true);
        try {
            const reviews = await getReviewsByCall(interaction.call_id);
            const groundTruthReview = reviews.find((review) => review.latest && review.template_id === templateID);
            if (groundTruthReview) {
                setGroundTruthReview(groundTruthReview);
            }
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('fetchGroundTruthReviewError', 'Failed to fetch data.'),
            });
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    }
    if (isLoading) {
        return React.createElement(Loading, { size: "small" });
    }
    if (!groundTruthReview) {
        return React.createElement(ScoreChip, { status: "NONE", value: 0, isHidden: true });
    }
    if (groundTruthReview.not_applicable) {
        return React.createElement(ScoreChip, null);
    }
    switch (groundTruthReview.status) {
        case 'PENDING_TRANSCRIPT':
            return React.createElement(PendingAiScoreIndicator, null);
        case 'FAILED':
            return React.createElement(FailedAiScoreIndicator, null);
        case 'SKIPPED':
            return React.createElement(SkippedStatusIndicator, null);
        default:
            return (React.createElement(ScoreChip, { value: groundTruthReview.score_percentage, status: groundTruthReview.compliance_status }));
    }
};
